<template>
  <vx-card>
    <div class="vx-row">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <vs-tabs>
            <vs-tab label="Monitoring">
              <div class="tab-text">
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Branch</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="territory"
                      :options="optionTerr"
                      :multiple="true"
                      track-by="id"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>SO Date From</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <datepicker :inline="false" v-model="soDateFrom"></datepicker>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>SO Date To</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <datepicker :inline="false" v-model="soDateTo"></datepicker>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Salesorder No ND6</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="SoNumber"
                      :options="optionSoNo"
                      :multiple="true"
                      track-by="code"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel2"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Doc Status</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="statusSelected"
                      :options="optionStatus"
                      :multiple="true"
                      track-by="id"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
                <!-- <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-button color="success" @click="handleDrawTable()"
                      >Get Data</vs-button
                    >
                  </div>
                </div> -->
                <data-table
                  :baseUrl="this.baseUrl"
                  :detail="this.detail"
                  :territoryIDs="this.territoryIDs"
                  :status="this.status"
                  :soDateFrom="this.soDateFrom"
                  :soDateTo="this.soDateTo"
                  :soNumberNd6="this.soNumberNd6"
                  :draw="draw"
                ></data-table>
              </div>
            </vs-tab>
            <vs-tab label="Export">
              <div class="tab-text">
                <data-table-export></data-table-export>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- <FormPlan @close="handleClose" :baseUrl="this.baseUrl"></FormPlan> -->
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DataTable from "./DataTable.vue";
import DataTableExport from "./DataTableExport.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    DataTableExport,
    DataTable,
    DateRangePicker,
    Datepicker
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      baseUrl: "/api/v1/report/cmi-monitoring",
      draw: 0,
      territoryIDs: "0",
      supplierIDs: "0",
      status: "All",
      statusSelected: [{id: "'All'", code: "-", name: "All Status"}],
      soDateFrom: "",
      soDateTo: "",
      soNumberNd6: "0",
      territory: [
        {
          id: 0,
          code: "",
          name: "",
        }
      ],
      optionTerr: [
        {
          id: 0,
          code: "",
          name: "",
        }
      ],
      SoNumber: [{code: "All"}],
      optionSoNo: [
        {
          code: "All",
        }
      ],
      optionStatus: [
        {id: "'All'", code: "-", name: "All Status"},
        {id: "'SO Draft'", code: "-", name: "SO Draft"},
        {id: "'SO Cancel'", code: "-", name: "SO Cancel"},
        {id: "'Peforma Invoice'", code: "-", name: "Peforma Invoice"},
        {id: "'Invoice'", code: "-", name: "Invoice"},
        {id: "'Invoice Canceled'", code: "-", name: "Invoice Canceled"},
        {id: "'Invoice Downloaded'", code: "-", name: "Invoice Downloaded"},
      ],
    };
  },
  methods: {
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("MMM DD, YYYY");
      }
      return a;
    },
    customLabel(val) {
      if (val) {
        return val.code === "-" ? `${val.name}` :`(${val.code}) ${val.name}`;
      }
    },
    customLabel2(val) {
      if (val) {
        return val.code;
      }
    },
    getOptionSoNo() {
      this.$http.get("/api/v1/report/cmi-monitoring/sales-order").then((resp) => {
        this.optionSoNo = [{ code: "All" }];
        this.optionSoNo = [...this.optionSoNo, ...resp.data.records.map(item => ({ code: item.code }))];
        this.SoNumber = [this.optionSoNo[0]];
      });
    },
    getOptionTerritory() {
      this.$http.get("/api/v1/master/territory").then(resp => {
        this.optionTerr = [{
            "id": 0,
            "code": "-",
            "name": "All Territory",
        }];
        this.optionTerr = [...this.optionTerr, ...resp.data.records];
        this.territory = [this.optionTerr[0]];
      });
    },
    handleClose() {
      this.detail = false;
    },
    handleOpen() {
      this.detail = true;
    },
    handleDrawTable() {
      this.draw++;
    }
  },
  watch: {
    SoNumber: {
      handler(newVal, oldVal) {
        const codes = newVal.map(item => item.code);
        this.soNumberNd6 = codes.join(',');
        if (codes[0] == "All" && codes.length > 1) {
          this.SoNumber.shift()
        } else if(codes.includes("All") && codes.length > 1) {
          this.soNumberNd6 = "0"
          this.SoNumber = [{
            "code": "All",
          }];
        }
      },
      deep: true
    },
    territory: {
      handler(newVal, oldVal) {
        const ids = newVal.map(item => item.id);
        this.territoryIDs = ids.join(',');
        if (ids[0] == 0 && ids.length > 1) {
          this.territory.shift()
        } else if(ids.includes(0) && ids.length > 1) {
          this.territoryIDs = "0"
          this.territory = [{
            "id": 0,
            "code": "-",
            "name": "All Territory",
          }];
        }
      },
      deep: true
    },
    statusSelected: {
      handler(newVal, oldVal) {
        const ids = newVal.map(item => item.id);
        this.status = ids.join(',');
        if (ids[0] == "'All'" && ids.length > 1) {
          this.statusSelected.shift()
        } else if(ids.includes("'All'") && ids.length > 1) {
          this.status = "'All'"
          this.statusSelected = [{id: "'All'", code: "-", name: "All Status"}]
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getOptionTerritory()
    this.getOptionSoNo()
    // const today = new Date();
    // this.filteDate.startDate = new Date(
    //   today.getFullYear(),
    //   today.getMonth() - 1,
    //   1
    // );
    // this.filteDate.endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  }
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
